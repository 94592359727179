import { $, $$ } from '../helpers/query-selector.js';
import { gsap } from 'gsap/all';

const body = document.body;
const header = $('.js-header');
const main = $('.js-main');
const footer = $('.js-footer');
const nav = $('.js-nav');
const navWrapper = $('.js-nav-wrapper');
const navToggle = $('.js-nav-toggle');
const navIcon = $('.js-nav-icon');
const navLinks = $$('a', navWrapper);
const navIconActiveClass = 'c-nav-icon--active';
const navActiveClass = 'c-nav--active';
let navActive = false;
let scrollPosition = 0;

function showNav() {
  navActive = true;

  navToggle.setAttribute('aria-expanded', 'true');
  navIcon.classList.add(navIconActiveClass);
  nav.classList.add(navActiveClass);

  // Anim
  gsap.from(navLinks, {
    translateY: '50%',
    opacity: 0,
    duration: 0.4,
    stagger: 0.04,
    ease: 'power2.out'
  });

  // Limit scroll
  scrollPosition = window.pageYOffset;

  const bodyTop = body.getBoundingClientRect().top;
  const mainTop = main.getBoundingClientRect().top;
  const footerTop = footer.getBoundingClientRect().top;

  for (const el of [main, footer]) {
    el.style.setProperty('position', 'fixed');
    el.style.setProperty('width', '100%');
    el.style.setProperty('opacity', 0);

    const elTop = el.classList.contains('js-main') ? mainTop : footerTop;
    el.style.setProperty('top', elTop - bodyTop - scrollPosition + 'px');
  }

  window.scrollTo(0, 0); // fix jumps
}

function hideNav() {
  navActive = false;

  navToggle.setAttribute('aria-expanded', 'false');
  navIcon.classList.remove(navIconActiveClass);
  nav.classList.remove(navActiveClass);

  // Reset scroll
  for (const el of [main, footer]) {
    el.style.removeProperty('position');
    el.style.removeProperty('width');
    el.style.removeProperty('opacity');
    el.style.removeProperty('top');
  }

  window.scrollTo(0, scrollPosition);
}

function initDynamicNavColor() {
  if (
    !header.classList.contains('l-header--white') &&
    !header.classList.contains('l-header--white-wide-only')
  )
    return;

  const firstModule = $('.js-main > article > *');
  if (!firstModule) return;

  const observer = new IntersectionObserver(
    (entries, observer) => {
      for (const entry of entries) {
        if (entry.isIntersecting) {
          nav.classList.remove('c-nav--black');
        } else {
          nav.classList.add('c-nav--black');
        }
      }
    },
    {
      rootMargin: '-52px 0px 0px 0px'
    }
  );

  observer.observe(firstModule);
}

function init() {
  // Init toggle button
  navToggle.addEventListener('click', () => {
    navActive ? hideNav() : showNav();
  });

  // Close nav if link is clicked
  // navWrapper.addEventListener('click', e => {
  //   if (navActive && e.target.tagName === 'A') hideNav();
  // });

  initDynamicNavColor();
}

export default init;
