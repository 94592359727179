// Match scss vars
const mq = {
  s: window.matchMedia(`(min-width: ${480 / 16}em)`),
  ms: window.matchMedia(`(min-width: ${720 / 16}em)`),
  m: window.matchMedia(`(min-width: ${960 / 16}em)`),
  ml: window.matchMedia(`(min-width: ${1280 / 16}em)`)
};

const ease = {
  out: '0.23, 1, 0.32, 1',
  in: '0, 0, 0.2, 1',
  inOut: '0.55, 0, 0.1, 1',
  fade: '0.25, 0, 0.25, 1'
};

const reducedMotion = window.matchMedia('(prefers-reduced-motion)');
const saveData =
  'connection' in navigator && navigator.connection.saveData === true;

export { mq, ease, reducedMotion, saveData };
