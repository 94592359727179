import '../scss/app.scss'; // Vite requires css import in app.js

import './modules/lazyload.js';
import './modules/set-vh.js';
import initNav from './modules/nav.js';
import initVideo from './modules/video.js';
import { $ } from './helpers/query-selector.js';

async function init() {
  initNav();
  initVideo();

  if ($('.js-lottie')) import('./modules/lottie.js');
  if ($('.js-slideshow')) import('./modules/slideshow.js');
  if ($('.js-intro')) import('./modules/intro.js');
  if ($('.js-paper-hero')) import('./modules/paper-hero.js');
  if ($('.js-layer-hero')) import('./modules/layer-hero.js');
  if ($('.js-text-image-columns')) import('./modules/text-image-columns.js');
  if ($('.js-freestyle')) import('./modules/freestyle.js');
  if ($('.js-countdown')) import('./modules/countdown.js');
  if ($('.js-scroll-text')) import('./modules/scroll-text.js');
  if ($('.js-type-tester')) import('./modules/type-tester.js');
  if ($('.js-character-set')) import('./modules/character-set.js');
  if ($('.js-order-form')) import('./modules/order-form.js');
  if ($('.js-order-button')) import('./modules/order-button.js');
  if ($('.js-download-button')) import('./modules/download-button.js');
  if ($('script[data-consent-required]')) import('./modules/cookie-consent.js');

  if ($('.js-newsletter-form'))
    import('./modules/newsletter-form.js').then(m => m.default());
  if ($('.js-creator')) import('./modules/creator.js').then(m => m.default());
  if ($('.js-anchor-nav'))
    import('./modules/anchor-nav.js').then(m => m.default());
  if ($('.js-products')) import('./modules/products.js').then(m => m.default());
}

init();
